<script>
import { bonus_extension } from "@/api/my";
import { kglj } from "@/api/my";
import Clipboard from "clipboard";
export default {
    name:'Promotion',
    components: {
        'mt-header': app.Mint.Header,
        'mt-button': app.Mint.Button,
        Header: () => import('components/base/Header'),
    },
    directives: {
        'infinite-scroll': app.Mint.InfiniteScroll,
    },
    data() {
        return {
            result: "",
            grandpa_id: "",
            parent_id: "1",
            list: [],
            loading_data: true,
            page: 1,
            pageSize: 20,
            totalPage: "",

            header: {
                mainTitle: getWord('promotion2'),
            },
        };
    },
    methods: {
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        },
        async copyText() {
            let clipboard = new Clipboard(".copy");
            clipboard.on("success", function(e) {
                if (clipboard) {
                    clipboard.destroy();
                }
            });
            app.Mint.Toast(getWord('clipboard_done'));
        },
        //推广链接
        tg() {
            kglj().then(result => {
                if (result.data.code == "SUCCESS") {
                    this.result = result.data.result.url;
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
        },
        //分页
        loadMore_data() {
            if (this.page >= this.totalPage) {
                return false;
            } else {
                this.page++;
                this.mybonus_extension();
            }
        },
        //推广红利
        mybonus_extension() {
            bonus_extension(
                this.grandpa_id,
                this.parent_id,
                this.page,
                this.pageSize
            ).then(result => {
                if (result.data.code == "SUCCESS") {
                    if (result.data.result.list.lengt==0) {
                        app.Mint.Toast(getWord('no_result_of_promotion_benefit'));
                    } else {
                        for (var i = 0; i < result.data.result.list.length ; i++) {
                            this.list.push(result.data.result.list[i]);
                        }
                        this.totalPage = result.data.result.totalPage;
                    }
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
            this.loading_data = false;
        },
        showPromotionKickbackList(){
            this.$router.push("/PromotionKickbackList");
        },
    },
    mounted() {
        this.tg();
        this.mybonus_extension();
        this.CustomScroll(this.$refs.promotionBonus.id);
    }
};
</script>

<template>
    <section id="promotion-bonus" ref="promotionBonus" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <div class="myposition"></div>
            <div class="content">
                <Header
                    :mainTitle="header.mainTitle"
                    :backButton=false
                />
                <div class="header">
                    <span class="header_span">{{ getWord('promotion2') }}</span>
                    <span style="float:left;max-width: 1.2rem;margin-left: 0.1rem;font-size: 0.24rem;font-weight:400;color:rgba(102,102,102,1);"  class="copy" :data-clipboard-text="result" @click="copyText()">{{result}}</span>
                </div>
                <div style="float: left;width: 100%;">
                    <span class="content_span">{{ getWord(['promotion', 'qrcode']) }}</span>
                    <div class="main">
                        <qriously :value="result" class="QR_code" style/>
                    </div>
                </div>

                <div style="background-color: white;width: 100%">
                    <div class="content_span" style>{{ getWord(['promotion', 'commission2']) }}</div>
                    <table
                        class="main_table"
                        cellspacing="0px"
                        v-infinite-scroll="loadMore_data"
                        infinite-scroll-disabled="loading_data"
                        infinite-scroll-distance="50"
                    >
                        <tr style="color:rgba(71,150,252,1);">
                            <th>{{ getWord('date') }}</th>
                            <th>{{ getWord('referral') }}</th>
                            <th>{{ getWord('referral_deposit') }}</th>
                            <th>{{ getWord(['earn', 'commission2']) }}</th>
                        </tr>
                        <tr style="color:rgba(102,102,102,1);" v-for="item in list">
                            <td>{{new Date(item.created_at*1000).toLocaleDateString().replace(/\//g, "-") + " " + new Date(item.created_at*1000).toTimeString().substr(0, 8)}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.pay_price}}</td>
                            <td>{{item.pre_price1 && Number(item.pre_price1).toFixed(2)}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <h3>{{ getWord('referral2') }}</h3>
            <div class="promotion-url">
                <a class="copy" :data-clipboard-text="result" @click="copyText()">{{ getWord('copy') }}</a>
                <label>{{ getWord('promo_link') }}</label>
                <span>{{result}}</span>                
            </div>

            <div class="qrcode">
                <h4>{{ getWord(['promotion', 'qrcode']) }}</h4>
                <qriously :value="result" />
                <a @click="showPromotionKickbackList()">{{ getWord('promotion_benefit_records') }}</a>
            </div>
        </template>
    </section>
</template>

<style scoped lang='scss' style="text/css">
#promotion-bonus {
    overflow:auto;    

    &.template-3 {
        height: 100%;
        background-color: #EEEEEE;

        h3 {
            color: #B0B0B0;
            font-size: .3rem;
            padding: .25rem;
        }

        .promotion-url {
            background-color:#ffffff;
            padding: .25rem;
            font-size: .28rem;
            border-bottom:1px solid #B0B0B0;

            > * {
                display: inline-block;
                vertical-align: middle;
            }

            a {
                float: right;
                color: #EC2829;
                font-size: .3rem;
            }

            label {
                color: #666666;
                width: 1.8rem;
            }

            > span {
                color: #B0B0B0;
                display: inline-block;
                width: calc(100% - 2.8rem);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .qrcode {
            background-color:#ffffff;
            padding: .25rem 0;
            font-size: .3rem;
            text-align: center;

            /deep/ canvas {
                margin:.25rem 0;
                width: 4.5rem;
            }

            a {
                color: #EC2829;
            }
        }
    }

    .header_span {
        float: left;
        width: 1.3rem;
        height: 0.3rem;
        font-size: 0.3rem;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        margin-top: 0.1rem;
    }
    .myposition {
        background-color: rgba(239, 239, 239, 1);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
    .header {
        position: relative;
        float: left;
        width: 7.5rem;
        height: 0.68rem;
        background-color: white;
        margin-top: 0.3rem;
    }

    .QR_code {
        position: absolute;
        margin: 5% 0 0 35%;
        width: 2rem;
        height: 2rem;
    }
    .dividend {
        float: left;
        width: 100%;
        background-color: white;
    }
    .content_span {
        float: left;
        width: 80%;
        height: 0.26rem;
        font-size: 0.24rem;
        margin: 0.6rem 0 0.1rem 0.2rem;
        color: rgba(102, 102, 102, 1);
        line-height: 0.26rem;
    }
    .main {
        float: left;
        width: 100%;
        height: 2.8rem;
        background-color: white;
        margin-top: 0.25rem;
        position: relative;
    }
    .main_table {
        float: left;
        width: 95%;
        border-collapse: collapse;
        border-top: 0;
        font-size: 0.3rem;
        text-align: center;
        box-shadow: 0 0 0.2rem #ccc;
        background-color: white;
        margin: 0.1rem 0 0 2.5%;
    }
    .main_table tr th {
        font-size: 0.24rem;
        color: rgba(71, 150, 252, 1);
        border: 0.5px solid rgba(187, 187, 187, 1);
        height: 0.68rem;
        width: 18%;
    }
    .main_table tr td {
        border: 1px solid rgba(187, 187, 187, 1);
        font-size: 0.22rem;
        color: rgba(102, 102, 102, 1);
        height: 0.68rem;
        width: 18%;
    }
}

</style>